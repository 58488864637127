import axios, { AxiosResponse } from 'axios';
import { getCommonConfig, baseUrl } from './api.service';

/**
 * This could be used as a health monitoring endpoint.  Currently gets only version.
 */
export const getHealth = async (): Promise<
  AxiosResponse<{ version: string }>
> => {
  const url = `${baseUrl()}/api/health`;

  return axios(getCommonConfig(url));
};
