/**
 * defined in db table `auth_role`
 *
 * finance tiers copied to /serverless/PendingApprovalNotification
 */
export enum AuthRole {
  Purchaser = 'purchaser',
  Shipper = 'shipper',
  ShipperAdmin = 'shipper-admin',
  ShipperPriceAdmin = 'shipper-price-admin',
  PurchaseOrderReader = 'purchase-order-reader',
  CycleCountAdmin = 'cycle-count-admin',
  CycleCountWriter = 'cycle-count-writer',
  CycleCountReader = 'cycle-count-reader',
  /**
   * Access to finance control data (ie: Monthly Pulls)
   */
  FinanceController = 'finance-controller',
  GlassesOrderWriter = 'glasses-order-writer',
  GlassesOrderReader = 'glasses-order-reader',
  InnovationsPlaceOrder = 'innovations-place-order',
  /**
   * can perform Customer Returns
   */
  CustomerReturnsAdmin = 'customer-returns-admin',
  /**
   * Can mark inventory as damaged or expired.  Both considered "damaged" fron PoV of finance
   */
  DamageAdmin = 'damage-admin',
  /**
   * Can transfer inventory to/from store.
   */
  StoreTransferAdmin = 'store-transfer-admin',
  /**
   * Can make manual adjusting entries to inventory levels.
   */
  InventoryAdmin = 'inventory-admin',
  /**
   * Can Bulk upload JIT returns.
   */
  JitReturns = 'jit-returns',
  /**
   * Can place JIT orders
   */
  JITter = 'jitter',
  /**
   * Can sync glasses/KITS labs transmit
   */
  JobRunnerGlassesTransmit = 'job-runner-glasses-transmit',
  /**
   * Can run scheduled jobs on demand for warehouse
   */
  JobRunnerWarehouse = 'job-runner-warehouse',
  /**
   * Can run scheduled jobs on demand for order processing
   */
  JobRunnerOrderProcessing = 'job-runner-order-processing',
  /**
   * Can mark frame breakage for inventory deductions
   */
  QualityAssurance = 'quality-assurance',
  /**
   * Can add/remove roles for users.  Basic functionality only as moving away from direct db commands.
   */
  UserAdmin = 'user-admin',
  /**
   * Can upload and view invoices
   */
  Invoice = 'invoice',
  /**
   * Highest tier of financial approval
   */
  FinanceTier1 = 'finance-tier-1',
  /**
   * Can approve up to tier-1
   */
  FinanceTier2 = 'finance-tier-2',
  /**
   * Basic warehouse functionality (ie: print labels)
   */
  WarehouseUser = 'warehouse-user',
  /**
   * Can do bulk reductions of inventory from a scan sheet
   */
  BulkReduction = 'bulk-reduction',
  /**
   * Used for CS agents, can use interface to process a return
   */
  CsAgent = 'cs-agent',
  /**
   * Used for CS agents, can use assigner dashboard to assign tickets
   */
  CsAgentAdmin = 'cs-agent-admin',
  /**
   * Used currently to set deposits on POs
   */
  AccountsPayable = 'accounts-payable',
  /**
   * Can update logistics feature flags to alter order processing behaviours
   */
  LogisticsAdmin = 'logistics-admin',
}
