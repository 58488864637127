import { computed, ComputedRef, Ref, ref } from 'vue';
import axios from 'axios';
import {
  destroyTokens,
  getRefreshToken,
  getToken,
  saveTokens,
} from './authToken';
import { baseUrl } from '@/services/api.service';
import { Nullable } from '@/types';

export type AuthUser = {
  id: number;
  name: string;
  email: string;
  photo?: string;
  provider: string;
  exp: number;
  roles: string[];
};

export type LoggedInState = {
  user: AuthUser;
  isAuthenticated: true;
};

export type NotLoggedInState = {
  user: undefined;
  isAuthenticated: false;
};

export type AuthState = (LoggedInState | NotLoggedInState) & {
  autologinInProgress: boolean;
};

const state: Ref<AuthState> = ref({
  user: undefined,
  isAuthenticated: false as false,
  autologinInProgress: true,
});

export type AuthStore = {
  state: ComputedRef<AuthState>;
  user: ComputedRef<AuthUser | undefined>;
  isAuthenticated: ComputedRef<boolean>;
  autologinInProgress: ComputedRef<boolean>;
  setUser: (user: AuthUser) => void;
  setUserFromToken: (token: string) => void;
  clearUser: () => void;
  hasRole: (authorizedRoles: string[]) => boolean;
  attemptRefresh: () => Promise<boolean>;
};

function parseJwt(token: string): AuthUser {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const user = JSON.parse(jsonPayload) as AuthUser;
  return user;
}

export const getUserFromToken = (token: string): AuthUser => {
  return parseJwt(token);
};

/**
 * Be mindful to only call this once at startup, since it will have multiple timeouts otherwise.
 * Users must logout to get udpated roles, if applicable with this strategy.
 */
const setUserFromToken = (token: string): void => {
  const user = parseJwt(token);
  state.value.autologinInProgress = false;
  state.value.isAuthenticated = true;
  state.value.user = user;
  console.log('authStore.isAuthenticated = true.  Starting refresh timer.');

  startExpirationTimer(user);
};

let authenticateTimeout: Nullable<number> = null;

/**
 * NOTE: we lost the strong typings that when you are authenticated the user is available when we did this.
 */
const store = (): AuthStore => {
  return {
    state: computed(() => state.value),
    user: computed(() => state.value.user),
    isAuthenticated: computed(() => state.value.isAuthenticated),
    autologinInProgress: computed(() => state.value.autologinInProgress),
    setUser: (user: AuthUser): void => {
      state.value.autologinInProgress = false;
      state.value.isAuthenticated = true;
      state.value.user = user;
    },
    setUserFromToken,
    clearUser: () => {
      state.value.isAuthenticated = false;
      state.value.user = undefined;
      if (authenticateTimeout !== null) {
        clearTimeout(authenticateTimeout);
        authenticateTimeout = null;
      }
    },
    hasRole: (authorizedRoles: string[]): boolean => {
      if (!state.value.isAuthenticated || state.value.user === undefined) {
        console.error('not authenticated');
        return false;
      }

      console.log(
        'authorized roles client-side`:',
        JSON.stringify(state.value.user!.roles)
      );

      return (
        authorizedRoles.filter((x) => state.value.user!.roles.includes(x))
          .length > 0
      );
    },
    /**
     * We have this for passport (Google SSO), but not bcrypt local
     */
    attemptRefresh: async (): Promise<boolean> => {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const refreshUrl = `${baseUrl()}/auth/refresh`;

        const tokens = {
          token: getToken(),
          refreshToken: getRefreshToken(),
        };

        // refresh token doesn't change (google doesn't send a new one)
        type RefreshResponse = {
          token: string;
          refreshToken: string;
        };

        try {
          const response = await axios.post<RefreshResponse>(
            refreshUrl,
            tokens
          );

          const { token, refreshToken } = response.data;
          saveTokens({ token, refreshToken });
          console.log('token refresh completed');
          return true;
        } catch (e) {
          console.error('error attempting refresh', e);
        }
      }
      return false;
    },
  };
};

const startExpirationTimer = (jwtToken: { exp: number }) => {
  // set a timeout to use the refresh token one minute before the JWT expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  console.log(`jwt expires in in ${Math.round(timeout / 1000)}s.`);
  authenticateTimeout = setTimeout(() => {
    (async () => {
      try {
        const { attemptRefresh, clearUser, user } = store();
        const provider = user.value ? user.value.provider : undefined;
        if (provider === 'google') {
          const refreshSucceeded = await attemptRefresh();
          if (refreshSucceeded) {
            const token = getToken()!;
            startExpirationTimer(getUserFromToken(token));
          } else {
            clearUser();
            destroyTokens();
          }
        } else {
          console.log('logging out user.', provider);
          clearUser();
          destroyTokens();
        }
      } catch (e) {
        console.error('error refreshing');
        console.error(e);
      }
    })();
  }, timeout);
};

export default store;
