<template>
  <div class="container">
    <div>
      <div
        v-if="!store.isAuthenticated.value || store.user.value === undefined"
      >
        <router-link to="/sign-in" class="btn btn-success" active-class="active"
          >Sign-in to use system</router-link
        >
        <div class="alert alert-dark" role="alert">
          <h4 class="alert-heading">Welcome!</h4>
          Log in to continue....
        </div>
      </div>
      <div v-else class="alert alert-dark" role="alert">
        <h4 class="alert-heading">Welcome!</h4>
        Logged in as {{ store.user.value.name }}. Use menu above to navigate.
      </div>
    </div>
  </div>
  <div class="text-center">
    <img alt="Vue logo" src="../assets/logo.png" />V1
  </div>
</template>

<script lang="ts">
import authStore from '../store/auth';

export default {
  name: 'Home',
  setup() {
    const store = authStore();
    return {
      store,
    };
  },
};
</script>
