<template>
  <Navigation />
  <div class="container">
    <div class="row gx-5">&nbsp;</div>
    <router-view />
  </div>
</template>
<script lang="ts">
import Navigation from './components/Navigation.vue';

export default {
  name: 'App',
  components: {
    Navigation,
  },
};
</script>
<style></style>
