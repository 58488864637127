const AUTH_TOKEN_KEY = 'auth.token';
const REFRESH_TOKEN_KEY = 'refresh.token';

export type Tokens = {
  token: string;
  refreshToken?: string;
};

export const getToken = (): string | null => {
  return window.localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * Considered moving this to in-memory.  The refresh token response does not contain profile information, so wanted to save a round trip
 */
export const saveTokens = (token: Tokens): void => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token.token);
  if (token.refreshToken !== undefined) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
  }
};

export const destroyTokens = (): void => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export default { getToken, getRefreshToken, saveTokens, destroyTokens };
