import { computed, ComputedRef, reactive } from 'vue';
import {
  createRouter,
  createWebHistory,
  LocationQuery,
  RouteLocationNormalized,
  RouteParams,
  RouteRecordRaw,
} from 'vue-router';

import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sign-in',
    name: 'Signin',
    component: () => import('@/views/Signin.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/token/:token/:refreshToken',
    name: 'token',
    component: () =>
      import(/* webpackChunkName: "token" */ '@/views/Token.vue'),
  },
  {
    path: '/invoice',
    name: 'invoices',
    component: () => import('@/views/Invoices.vue'),
  },
  {
    path: '/finance-reports',
    name: 'finance-reports',
    component: () => import('@/views/FinanceReports.vue'),
  },
  {
    path: '/finance-reports/:id',
    name: 'finance-report',
    component: () => import('@/views/FinanceReport.vue'),
  },
  {
    path: '/finance-monthly-pulls',
    name: 'finance-monthly-pulls',
    component: () => import('@/views/FinanceMonthlyPulls.vue'),
  },
  {
    path: '/finance-monthly-pull/:id',
    name: 'finance-monthly-pull',
    component: () => import('@/views/FinanceMonthlyPull.vue'),
  },
  {
    path: '/invoice/new',
    name: 'invoice-new',
    component: () => import('@/views/InvoiceNew.vue'),
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/InvoiceEdit.vue'),
    children: [
      {
        path: 'summary',
        name: 'invoice-summary',
        component: () => import('@/views/InvoiceSummary.vue'),
      },
      {
        path: 'products',
        name: 'invoice-products',
        component: () => import('@/views/InvoiceProducts.vue'),
      },
    ],
  },
  {
    path: '/containers',
    name: 'containers',
    component: () => import('@/views/PurchaseOrderContainers.vue'),
  },
  {
    path: '/container/:containerId',
    name: 'container',
    component: () => import('@/views/PurchaseOrderContainer.vue'),
  },
  {
    path: '/purchaseOrder',
    name: 'purchase-orders',
    component: () => import('@/views/PurchaseOrders.vue'),
  },
  {
    path: '/returnItemEdit/:id',
    name: 'editable-return-item',
    component: () => import('@/views/ReturnItemEdit.vue'),
  },
  {
    path: '/purchaseOrder/:id',
    name: 'purchase-order',
    component: () => import('@/views/PurchaseOrderEdit.vue'),
    children: [
      {
        path: 'shipment/:shipmentId',
        name: 'purchase-order-shipment',
        component: () => import('@/views/PurchaseOrderShipment.vue'),
      },
      {
        path: 'shipment/new',
        name: 'purchase-order-shipment-new',
        component: () => import('@/views/PurchaseOrderShipmentNew.vue'),
      },
      {
        path: 'items',
        name: 'purchase-order-items',
        component: () => import('@/views/PurchaseOrderItems.vue'),
      },
    ],
  },
  {
    name: 'trustedSupplier',
    path: '/trustedSupplier',
    component: () => import('@/views/TrustedSupplier.vue'),
  },
  {
    path: '/receive',
    name: 'purchase-order-receive',
    component: () => import('@/views/Receive.vue'),
  },
  {
    name: 'purchaseOrderNew',
    path: '/purchaseOrderNew',
    props: true,
    component: () => import('@/views/PurchaseOrderEdit.vue'),
  },
  {
    name: 'store-inventory',
    path: '/storeInventory',
    component: () => import('@/views/StoreInventory.vue'),
  },
  {
    path: '/customerReturns',
    name: 'customer-returns',
    component: () => import('@/views/CustomerReturnsV2.vue'),
  },
  {
    path: '/reportDamage',
    name: 'report-damage',
    component: () => import('@/views/ReportDamage.vue'),
  },
  {
    name: 'supplier-maintenance',
    path: '/supplierMaintenanceScreen',
    component: () => import('@/views/SupplierMaintenanceScreen.vue'),
  },
  {
    path: '/inventoryAdjust',
    name: 'inventory-adjust',
    component: () => import('@/views/InventoryAdjustment.vue'),
  },
  {
    path: '/inventoryJitReturns',
    name: 'inventory-jit-returns',
    component: () => import('@/views/InventoryJitReturns.vue'),
  },
  {
    path: '/inventoryBulkReduction',
    name: 'inventory-bulk-reductions',
    component: () => import('@/views/InventoryBulkReductions.vue'),
  },
  {
    path: '/bulkCustomerReturns',
    name: 'inventory-bulk-additions',
    component: () => import('@/views/BulkCustomerReturns.vue'),
  },
  {
    path: '/frameBreakage',
    name: 'frame-breakage',
    component: () => import('@/views/FrameBreakage.vue'),
  },
  {
    path: '/print/frames',
    name: 'print-frames',
    component: () => import('@/views/PrintLabels.vue'),
  },
  {
    path: '/shipment',
    name: 'shipments',
    component: () => import('@/views/Shipments.vue'),
  },
  {
    path: '/cycleCount',
    name: 'cycle-counts',
    component: () => import('@/views/CycleCounts.vue'),
  },
  {
    path: '/cycleCount/:id',
    name: 'cycle-count',
    component: () => import('@/views/CycleCount.vue'),
  },
  {
    path: '/cycleCount/:id/scan/:locationName',
    name: 'cycle-count-scan',
    component: () => import('@/views/CycleCountScan.vue'),
  },
  {
    path: '/cycle-count-edit/:id?',
    name: 'cycle-count-editor',
    props: true,
    component: () => import('@/views/CycleCountEdit.vue'),
  },
  {
    path: '/cycleCount/bulkUpload',
    name: 'cycle-count-bulk-upload',
    component: () => import('@/views/CycleCountBulkUpload.vue'),
  },
  {
    path: '/glassesOrder',
    name: 'glasses-orders',
    component: () => import('@/views/GlassesOrders.vue'),
  },
  {
    path: '/glassesOrderFBK',
    name: 'glasses-orders-fbk',
    component: () => import('@/views/GlassesOrdersFBK.vue'),
  },
  {
    path: '/csReturnsInterface',
    name: 'cs-returns-interface',
    component: () => import('@/views/CsReturnsInterfaceIdea2.vue'),
  },
  {
    path: '/returnsVerification',
    name: 'returns-verification',
    component: () => import('@/views/ProductConditionVerificationRequests.vue'),
  },
  {
    path: '/returnItemRequestAssignment',
    name: 'returns-assigner',
    component: () => import('@/views/ReturnTicketAssignerDashboard.vue'),
  },
  {
    path: '/csAssignedItems',
    name: 'assigned-items',
    component: () => import('@/views/CsAssignedItems.vue'),
  },
  {
    path: '/actionableItems',
    name: 'actionable-items',
    component: () => import('@/views/CsActionableItems.vue'),
  },
  {
    path: '/returnItemsViewer',
    name: 'return-items-viewer',
    component: () => import('@/views/ReturnItemsViewer.vue'),
  },
  {
    path: '/glassesOrderShip',
    name: 'glasses-orders-ship',
    component: () => import('@/views/GlassesOrdersShipping.vue'),
  },
  {
    path: '/glassesOrder/:id',
    name: 'glasses-order',
    component: () => import('@/views/GlassesOrder.vue'),
  },
  {
    path: '/glassesOrderFBK/:id',
    name: 'glasses-order-fbk',
    component: () => import('@/views/GlassesOrderFBK.vue'),
  },
  {
    path: '/glassesTransmit',
    name: 'glasses-transmits',
    component: () => import('@/views/GlassesTransmits.vue'),
  },
  {
    path: '/innovationsPlaceOrder',
    name: 'innovations-place-order',
    component: () => import('@/views/InnovationsPlaceOrder.vue'),
  },
  {
    path: '/jit/settings',
    name: 'JIT-settings',
    component: () => import('@/views/JITSettings.vue'),
  },
  {
    path: '/jit/:supplier',
    name: 'JITs',
    component: () => import('@/views/JITs.vue'),
  },
  {
    path: '/order/allied/sto',
    name: 'Allied-sto',
    component: () => import('@/views/SendAlliedSTO.vue'),
  },
  {
    path: '/order/abb/manual',
    name: 'abb-manual',
    component: () => import('@/views/OrderProcessingUploadABB.vue'),
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue'),
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('@/views/Jobs.vue'),
  },
  {
    path: '/settings/zebra',
    name: 'ZebraSettings',
    component: () => import('@/views/ZebraSettings.vue'),
  },
  {
    path: '/converters',
    name: 'Converters',
    component: () => import('@/views/Converters.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
  },
  {
    path: '/user/:id?',
    name: 'user',
    props: true,
    component: () => import('@/views/User.vue'),
  },
  {
    path: '/logistics/supplierQuotas',
    name: 'SupplierQuotas',
    component: () => import('@/views/SupplierQuotas.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(
    '/' /* in router source code this is the fallback */
  ),
  routes,
});

// allow registration for all route changes with a hook
type RouteDataType = {
  params: RouteParams;
  query: LocationQuery;
};

const routeData = reactive<RouteDataType>({ params: {}, query: {} });
router.afterEach((route: RouteLocationNormalized) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export const useParams = (): ComputedRef<RouteParams> => {
  return computed(() => routeData.params);
};

export default router;
