import { AxiosRequestConfig, ResponseType } from 'axios';

import AuthTokenStore from '../store/authToken';

export const VUE_APP_BASE_URL = 'VUE_APP_BASE_URL';

export const baseUrl = (): string => {
  const base =
    process.env[VUE_APP_BASE_URL] !== undefined
      ? (process.env[VUE_APP_BASE_URL] as string)
      : '';
  // console.log('base:', base);
  return base;
};

export type PagedFetchOptions = {
  offset: number;
  limit: number;
};

export const getCommonConfig = (
  url: string,
  params?: any,
  responseType?: ResponseType
): AxiosRequestConfig => {
  // or set on axios: axios.defaults.headers.common["Authorization"] = `Bearer ${...}`;
  const token = AuthTokenStore.getToken();
  const config: AxiosRequestConfig = {
    url,
    headers: { Authorization: `Bearer ${token}` },
    params,
    method: 'GET',
    responseType,
  };
  return config;
};

/**
 * Handles updates (PUT) and creates (POST) to API endpoints.
 */
export const writeCommonConfig = (
  url: string,
  verb: 'PATCH' | 'POST' | 'PUT' | 'DELETE',
  data?: any,
  responseType?: ResponseType
): AxiosRequestConfig => {
  // or set on axios: axios.defaults.headers.common["Authorization"] = `Bearer ${...}`;
  const token = AuthTokenStore.getToken();
  const config: AxiosRequestConfig = {
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data,
    method: verb,
    responseType,
  };
  return config;
};

export const uploadMultipartCommonConfig = (
  url: string,
  payload: object,
  file: File,
  responseType?: ResponseType
): AxiosRequestConfig => {
  const json = JSON.stringify(payload);
  const blob = new Blob([json], {
    type: 'application/json',
  });
  const data = new FormData();
  data.append('document', blob);
  data.append('file', file);

  const token = AuthTokenStore.getToken();
  const config: AxiosRequestConfig = {
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
    method: 'POST',
    responseType,
  };
  return config;
};
