import { createApp } from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import App from './App.vue';
import router from './router';
import autoLogin from '@/services/autoLogin.service';

createApp(App).use(router).use(autoLogin).mount('#app');
