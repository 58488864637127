import { App } from 'vue';

import authStore, { getUserFromToken } from '@/store/auth';
import { getToken, destroyTokens } from '@/store/authToken';

// hoist to Vue (can also do an IIFE to achieve same result)
export default {
  install: async (app: App) => {
    const token = getToken();

    const isExpired = (token: { exp: number }) => {
      if (token.exp * 1000 < Date.now()) {
        console.log('token is expired');
        return true;
      }
      return false;
    };

    if (token && !isExpired(getUserFromToken(token))) {
      const { setUserFromToken } = authStore();
      // starts refresh timer
      setUserFromToken(token);
    } else {
      const { attemptRefresh } = authStore();
      const refreshSucceeded = await attemptRefresh();
      console.log('autoLogin refresh completed', refreshSucceeded);
      if (refreshSucceeded) {
        const token = getToken()!;
        const { setUserFromToken } = authStore();
        // starts refresh timer
        setUserFromToken(token);
      } else {
        // ie: invalid_grant (bad request)
        destroyTokens();
      }
    }
  },
};
